import { Assignment, Delete, DeleteOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import CustomTable, {
  TableAction,
  TableConfig,
  TableField,
} from '../../../components/Table/CustomTable';
import { appURI, defaultPaging, oneofficeURI } from '../../../config';
import { getToken } from '../../../localstorage/auth';
import { useNavigate } from 'react-router-dom';
import { getTokenUser } from '../../../auth/auth';
import { useState } from 'react';

const MyOrders = () => {
  const navigate = useNavigate();
  const user = getTokenUser();
  const [eventID, setEventID] = useState(-1);

  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/orders/employer`,
        headers: endPointHeaders,
      },
    },
    user.drivers?.length > 1
      ? [
          new TableAction(
            'remove',
            <DeleteOutlined style={{ color: '#ff4e3b', fontSize: 24 }} />,
            'Teruggeven',
            null,
            true
          ),
          new TableAction(
            'plan',
            null,
            'Doorgeven',
            {
              variant: 'contained',
              color: 'primary',
            },
            true
          ),
        ]
      : [
          new TableAction(
            'remove',
            <DeleteOutlined style={{ color: '#ff4e3b', fontSize: 24 }} />,
            'Teruggeven',
            null,
            true
          ),
        ],
    defaultPaging,
    {
      order: 'asc',
      orderBy: 'route.firstPickupTime',
    },
    [
      new TableField('ordernr', 'ordernr', 'Order'),
      new TableField('driver', 'driver.alias', 'Chauffeur'),
      new TableField('execDateUTC', 'execDateUTC', 'Datum', 0, 0, 'EE dd/MM'),
      new TableField(
        'pickuptime',
        'route.firstPickupTime',
        'Ophalen',
        0,
        0,
        'HH:mm'
      ),
      new TableField('vehicleType', 'vehicle.model.category.name', 'Voertuig'),
      new TableField('route', 'route.traject.cityLocations', 'Route'),
    ]
  );

  const removeOrder = (selectedIds, selectedOrders) => {
    if (
      !selectedIds ||
      !selectedIds.length ||
      !selectedOrders ||
      !selectedOrders.length
    )
      return;
    if (selectedOrders[0].execDateUTC < Date.now()) {
      return Swal.fire(
        'Probleem',
        'Deze rit moet vandaag nog uitgevoerd worden, gelieve Gorselé te contacteren om de rit terug te geven.',
        'error'
      );
    }
    Swal.fire({
      title: 'Teruggeven',
      text: 'Ben je zeker dat je rit wil teruggeven aan Gorselé en van de planning wil verwijderen?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ja geef terug',
      cancelButtonText: 'Annuleer',
    }).then(async (planResult) => {
      if (planResult && planResult.isConfirmed) {
        const removeBody = {
          orderid: selectedOrders[0]._id,
        };
        const removeOneOffice = await fetch(
          `${oneofficeURI}/gorseleone/removeorder`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(removeBody),
          }
        );
        const oneOfficeData = await removeOneOffice.json();
        if (!oneOfficeData || !oneOfficeData.success) {
          Swal.fire(
            'Probleem',
            'Het order kon niet teruggegeven worden. Gelieve Gorselé te contacteren.',
            'error'
          );
          return;
        }
        const removeGorseleOne = await fetch(
          `${appURI}protected/orders/removeorder`,
          {
            method: 'POST',
            headers: endPointHeaders,
            body: JSON.stringify(removeBody),
          }
        );
        const gorseleOneData = await removeGorseleOne.json();
        if (!gorseleOneData || !gorseleOneData.success) {
          Swal.fire(
            'Probleem',
            'Er is een probleem opgetreden tijdens het teruggeven. Indien het probleem blijft aanhouden contacteer Gorselé.',
            'error'
          );
          return;
        }
        Swal.fire(
          'Teruggegeven',
          'Het teruggeven van de rit is afgerond en je ontvangt zo dadelijk een bevestigingsmail.',
          'success'
        );
        setEventID(Date.now());
      }
    });
  };

  const planOrder = (selectedIds, selectedOrders) => {
    if (
      !selectedIds ||
      !selectedIds.length ||
      !selectedOrders ||
      !selectedOrders.length
    )
      return;
    if (selectedOrders[0].execDateUTC < Date.now()) {
      return Swal.fire(
        'Probleem',
        'Deze rit moet vandaag nog uitgevoerd worden, gelieve Gorselé te contacteren om de rit door te geven.',
        'error'
      );
    }
    return navigate(`/plannedorders/${selectedIds[0]}`, {
      state: { drivers: selectedOrders[0].driversForPlanning },
    });
  };

  const handleAction = (action, selectedIds, selectedItems) => {
    switch (action.id) {
      case 'plan':
        planOrder(selectedIds, selectedItems);
        break;
      case 'remove':
        removeOrder(selectedIds, selectedItems);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <CustomTable
        handleEvent={eventID}
        config={tableConfig}
        noSelection
        onAction={handleAction}
      />
    </div>
  );
};

export default MyOrders;
