import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import S3FileUpload from 'react-s3';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Swal from 'sweetalert2';
import styles from './FileUpload.module.scss';
window.Buffer = window.Buffer || require('buffer').Buffer;

const FileUpload = ({
  accept,
  multiple,
  maxSizeKB,
  onUploadComplete,
  onError,
  bucketName,
  awsAccessKey,
  awsSecretKey,
  prefix,
}) => {
  const [files, setFiles] = useState();
  const [uploading, setUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [documentName, setDocumentName] = useState();

  const fileChange = (e) => {
    e.preventDefault();
    let files = e.target.files;
    if (maxSizeKB && parseInt(maxSizeKB)) {
      for (let f = 0; f < files.length; f++) {
        const size = (files[f].size / 1024).toFixed(4);
        if (size > maxSizeKB) {
          document.getElementById('imageuploader').value = '';
          return Swal.fire(
            'Probleem bestand',
            'Eén van de bestanden is te groot. De maximum bestandsgrootte is ' +
              maxSizeKB,
            'error'
          );
        }
      }
    }
    setFiles(files);
  };

  const uploadFiles = (e) => {
    e.preventDefault();
    if (!files) return;
    const filePrefix = prefix || 'cmsupload';
    const config = {
      bucketName: bucketName,
      region: 'eu-central-1',
      accessKeyId: awsAccessKey,
      secretAccessKey: awsSecretKey,
      dirName: filePrefix + '_' + Date.now(),
    };
    let fileIndex = 0;
    let uploadedFiles = [];
    const uploadAFile = () => {
      const currentFile = files[fileIndex];
      setCurrentFile(fileIndex + 1);
      setTimeout(() => {
        let extensions = currentFile.name.split('.');
        let newName =
          extensions.slice(0, extensions.length - 1).join('') + Date.now();
        newName =
          newName
            .replace(/[^a-z0-9_]+/gi, '-')
            .replace(/^-|-$/g, '')
            .toLowerCase() +
          '.' +
          extensions[extensions.length - 1];
        config.Key = newName;
        setTimeout(() => {
          S3FileUpload.uploadFile(currentFile, config)
            .then((data) => {
              setUploading(false);
              uploadedFiles.push({
                name: files[fileIndex].name,
                originalName: `${documentName} b${fileIndex}`,
                location: encodeURI(data.location),
                Location: encodeURI(data.location),
                key: data.key,
                Key: data.key,
                createdBy: {
                  _id: '55a7ad97bd7a52bf061695a8',
                  fullName: 'Neeckx Ken',
                },
                created: Date.now(),
                fileType: files[fileIndex].type,
                Bucket: data.bucket,
              });
              fileIndex++;
              if (fileIndex < files.length) {
                uploadAFile();
              } else {
                setFiles(undefined);
                setDocumentName(undefined);
                document.getElementById('imageuploader').value = '';
                return onUploadComplete(uploadedFiles);
              }
            })
            .catch((err) => {
              setUploading(false);
              console.log(err);
              return onError();
            });
        }, 1000);
      }, 50);
    };
    setUploading(true);
    setCurrentFile(0);
    setTotalFiles(files.length);
    uploadAFile();
  };

  return (
    <div className={styles.root}>
      <TextField
        id="imageuploader"
        type="file"
        onChange={(e) => fileChange(e)}
        accept={accept || '*'}
        multiple={multiple || false}
        className={styles.fileinput}
      />
      <TextField
        type="text"
        id="documentName"
        style={{ marginTop: 8, marginBottom: 8 }}
        value={documentName || ''}
        onChange={(e) => setDocumentName(e.currentTarget.value)}
        label="Geef een naam voor het bestand"
      />
      <div>
        <Button
          variant="contained"
          disabled={!files || !documentName || documentName.length < 3}
          onClick={(e) => uploadFiles(e)}
          className={styles.btn}
        >
          {uploading
            ? `Bezig met uploaden ${currentFile} van ${totalFiles}`
            : 'Upload'}
        </Button>
      </div>
      {uploading && (
        <ScaleLoader size={45} color={'#000000'} loading={uploading} />
      )}
    </div>
  );
};

export default FileUpload;
