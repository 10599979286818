import { createSlice } from '@reduxjs/toolkit';
import config from '../../config/app-config';

const initialState = {
  fetching: false,
  offline: window ? !window.navigator.onLine : true,
  currentLocale: localStorage.getItem('locale') || undefined,
  config: config,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      localStorage.setItem('locale', action.payload);
      state.currentLocale = action.payload;
    },
    clearLocale: (state) => {
      state.currentLocale = undefined;
    },
    setOffline: (state, action) => {
      state.offline = action.payload || false;
    },
    setFetching: (state, action) => {
      state.fetching = action.payload || false;
    },
  },
});

export const { setLocale, clearLocale, setOffline, setFetching } =
  appSlice.actions;

export const selectLocale = (state) => state.app.currentLocale;
export const selectFetching = (state) => state.app.fetching;

export default appSlice.reducer;
