import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { oneofficeURI } from '../../config';

export const garageApi = createApi({
  reducerPath: 'garageApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${oneofficeURI}/gorseleone/` }),
  endpoints: (builder) => ({
    listVehicleModels: builder.query({
      query: () => 'vehiclemodels/',
    }),
  }),
});

export const { useListVehicleModelsQuery } = garageApi;
