import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { getTokenUser } from '../../../auth/auth';
import FileUpload from '../../../components/Form/FileUpload';
import Form from '../../../components/Form/Form';
import Page from '../../../components/Page/Page';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import {
  appURI,
  awsAccessKey,
  awsSecretKey,
  bucketName,
  oneofficeURI,
} from '../../../config';
import {
  useListCitiesByCountryQuery,
  useListCountriesQuery,
} from '../../../services/geodata/geodataApi';
import { searchSafe } from '../../../utils/text';

const nationalities = [
  { value: 'belgian', translation: 'Belgisch' },
  { value: 'dutchman', translation: 'Nederlands' },
  { value: 'frenchman', translation: 'Frans' },
  { value: 'turkish', translation: 'Turks' },
];
const marriageStates = [
  { value: 'single', translation: 'Alleenstaand' },
  { value: 'married', translation: 'Getrouwd' },
  { value: 'divorsed', translation: 'Gescheiden' },
  { value: 'widowed', translation: 'Weduwe' },
  { value: 'cohabit', translation: 'Samenwonend' },
];
const driversLicenses = [
  'A',
  'B',
  'B+E',
  'C',
  'C1',
  'C+E',
  'C1+E',
  'D',
  'D1',
  'D+E',
  'D1+E',
  'G',
];

const AddStaff = () => {
  const [item, setItem] = useState({});
  const [sending, setSending] = useState(false);
  const [citySearch, setCitySearch] = useState(undefined);
  const navigate = useNavigate();
  const { data: countries, isLoading: countriesLoading } =
    useListCountriesQuery();
  const {
    data: cities,
    isLoading: citiesLoading,
    isFetching: citiesFetching,
  } = useListCitiesByCountryQuery(
    {
      country: item?.country?._id,
      search: citySearch ?? null,
    },
    { skip: !item.country || !citySearch }
  );
  let asyncTimeOut;

  const updateItem = (prop, val) => {
    setItem({
      ...item,
      [prop]: val,
    });
  };

  //search for cities on select text change
  const citiesTextChange = (val) => {
    val = val || '';
    let safeVal = searchSafe(val, true);
    if (safeVal && safeVal.length >= 3) {
      clearTimeout(asyncTimeOut);
      asyncTimeOut = setTimeout(() => {
        setCitySearch(val);
        clearTimeout(asyncTimeOut);
      }, 600);
    }
    return val;
  };

  const uploadComplete = (documents) => {
    Swal.fire('Gelukt', 'Upload afgerond', 'success');
    const currentDocs = item.documents || [];
    const newDocs = currentDocs.concat(documents);
    setItem({
      ...item,
      documents: newDocs,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    const user = getTokenUser();
    setSending(true);
    const url = `${oneofficeURI}/gorseleone/addstaffv2`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        staff: {
          ...item,
        },
      }),
    });
    setSending(false);
    const data = await res.json();
    if (!data || !data.success) {
      if (data && data.error) {
        return Swal.fire('Probleem', data.error, 'error');
      }
      return Swal.fire(
        'Probleem',
        'Er is een probleem opgetreden tijdens het aanmaken van het personeelslid. Probeer het later opnieuw of contacteer Gorselé.',
        'error'
      );
    }
    Swal.fire(
      'Gelukt',
      'Het nieuwe personeelslid werd aangemaakt en wordt nu door een dispatcher van Gorselé gecontroleerd. Dit proces kan tot 24u duren. Indien alle gegevens in orde zijn zal het personeelslid beschikbaar zijn om in te plannen.',
      'success'
    );
    return navigate('/staff');
  };

  return (
    <Page>
      <Form onSubmit={submit} fullwidth>
        <h1>Nieuw personeelslid</h1>
        <TextField
          required
          value={item.lastName || ''}
          onChange={(e) => updateItem('lastName', e.target.value)}
          label="Naam"
          margin="dense"
        />
        <TextField
          required
          value={item.firstName || ''}
          onChange={(e) => updateItem('firstName', e.target.value)}
          label="Voornaam"
          margin="dense"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.birthDate || ''}
          onChange={(e) => updateItem('birthDate', e.target.value)}
          label="Geboortedatum"
          placeholder=""
          margin="dense"
        />
        <TextField
          required
          value={item.birthPlace || ''}
          onChange={(e) => updateItem('birthPlace', e.target.value)}
          label="Geboorteplaats"
          margin="dense"
        />
        <StyledSelect
          required
          options={countries || []}
          isSearchable={true}
          value={item.birthCountry || null}
          placeholder="Geboorteland"
          noOptionsMessage={() => 'Geen landen'}
          getOptionLabel={(option) => option['country']}
          isLoading={countriesLoading}
          onChange={(value) => updateItem('birthCountry', value)}
        />
        <TextField
          value={item.tel || ''}
          onChange={(e) => updateItem('tel', e.target.value)}
          label="TEL"
          margin="dense"
        />
        <TextField
          required
          value={item.gsmWork || ''}
          onChange={(e) => updateItem('gsmWork', e.target.value)}
          label="GSM"
          margin="dense"
        />
        <TextField
          required
          value={item.email || ''}
          onChange={(e) => updateItem('email', e.target.value)}
          label={
            <FormattedMessage
              id="app.signup.form.email"
              defaultMessage="Email"
            />
          }
          margin="dense"
        />
        <TextField
          required
          value={item.street || ''}
          onChange={(e) => updateItem('street', e.target.value)}
          label="Straat en huisnummer"
          margin="dense"
        />
        <StyledSelect
          required
          options={countries || []}
          isSearchable={true}
          value={item.country || null}
          placeholder="Selecteer land"
          noOptionsMessage={() => 'Geen landen'}
          getOptionLabel={(option) => option['country']}
          isLoading={countriesLoading}
          onChange={(value) => updateItem('country', value)}
        />
        {item.country && (
          <StyledSelect
            required
            options={cities || []}
            isSearchable={true}
            value={item.city || null}
            placeholder="Selecteer woonplaats"
            noOptionsMessage={() =>
              citySearch ? 'Geen woonplaatsen gevonden' : 'Zoek woonplaatsen'
            }
            getOptionLabel={(option) => option['fullCity']}
            isLoading={citiesLoading || citiesFetching}
            onChange={(value) => updateItem('city', value)}
            onInputChange={citiesTextChange}
          />
        )}
        <FormControl fullWidth margin="dense">
          <InputLabel>Nationaliteit</InputLabel>
          <Select
            required
            placeholder="Selecteer nationaliteit"
            value={item.nationality || ''}
            label="Nationaliteit"
            onChange={(e) => updateItem('nationality', e.target.value)}
            margin="dense"
          >
            {nationalities.map((nat, n) => (
              <MenuItem key={n} value={nat.value}>
                {nat.translation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          value={item.registerNr || ''}
          onChange={(e) => updateItem('registerNr', e.target.value)}
          label="Rijksregister nummer"
          margin="dense"
        />
        <TextField
          required
          value={item.passportNr || ''}
          onChange={(e) => updateItem('passportNr', e.target.value)}
          label="Identiteitskaart nummer"
          margin="dense"
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Burgerlijke staat</InputLabel>
          <Select
            required
            placeholder="Selecteer"
            value={item.marriageState || ''}
            label="Burgerlijke staat"
            onChange={(e) => updateItem('marriageState', e.target.value)}
            margin="dense"
          >
            {marriageStates.map((ms, m) => (
              <MenuItem key={m} value={ms.value}>
                {ms.translation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel>Rijbewijs Type</InputLabel>
          <Select
            required
            placeholder="Selecteer type rijbewijs"
            value={item.driversLicense || ''}
            label="Nationaliteit"
            onChange={(e) => updateItem('driversLicense', e.target.value)}
            margin="dense"
          >
            {driversLicenses.map((dl, d) => (
              <MenuItem key={d} value={dl}>
                {dl}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          value={item.driversLicenseNr || ''}
          onChange={(e) => updateItem('driversLicenseNr', e.target.value)}
          label="Rijbewijs nummer"
          margin="dense"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.driverPassEndDate || ''}
          onChange={(e) => updateItem('driverPassEndDate', e.target.value)}
          label="Vervaldatum bestuurderspas"
          placeholder=""
          margin="dense"
        />
        <TextField
          required
          value={item.driverPassNumber || ''}
          onChange={(e) => updateItem('driverPassNumber', e.target.value)}
          label="Bestuurderspas nummer"
          margin="dense"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.medicalExaminationDate || ''}
          onChange={(e) => updateItem('medicalExaminationDate', e.target.value)}
          label="Vervaldatum medische schifting"
          placeholder=""
          margin="dense"
        />
        {item.documents && (
          <fieldset>
            <legend>Documenten</legend>
            <table width="100%">
              <tbody>
                {item.documents.map((doc, d) => (
                  <tr key={d}>
                    <td>{doc.originalName}</td>
                    <td>
                      <a href={doc.Location} target="_blank" rel="noreferrer">
                        Open document
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </fieldset>
        )}
        <fieldset>
          <legend>Voeg documenten toe</legend>
          <p className="hint">
            Gelieve voor- en achterkant van identiteitskaart en rijbewijs toe te
            voegen en alles in aparte documenten te uploaden!
          </p>
          <FileUpload
            onUploadComplete={(files) => uploadComplete(files)}
            onError={() => Swal.fire('Probleem met upload', 'error')}
            bucketName={bucketName}
            awsAccessKey={awsAccessKey}
            awsSecretKey={awsSecretKey}
            multiple={false}
            accept="image/png, image/jpeg"
            prefix="staff"
          />
        </fieldset>
        <div>
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            size="large"
            type="submit"
          >
            Opslaan
          </Button>
        </div>
        {sending && (
          <ScaleLoader
            size={45}
            color={'#000000'}
            loading={true}
            style={{ marginTop: 20 }}
          />
        )}
      </Form>
    </Page>
  );
};

export default AddStaff;
