import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import HashedRoute from '../../HashedRoute';
import OrderDetail from './orders/detail/OrderDetail';
import ExecutedOrders from './orders/ExecutedOrders';
import MyOrders from './orders/MyOrders';
import Orders from './orders/Orders';
import ExecutedOrdersReport from './reports/report/ExecutedOrdersReport';
import PlannedOrders from './reports/report/PlannedOrders';
import PricedOrders from './reports/report/PricedOrders';
import Reports from './reports/Reports';
import AddStaff from './staff/AddStaff';
import Staff from './staff/Staff';
import AddVehicle from './vehicles/AddVehicle';
import Vehicles from './vehicles/Vehicles';
import PlannedOrderDetail from './orders/detail/PlannedOrderDetail';

const DEFAULTPATHNAME = 'Orders';

const routes = [
  {
    path: '/orders/:orderid',
    pathForTitle: '/orders/',
    component: OrderDetail,
    title: 'Order Detail',
  },
  {
    path: '/orders',
    component: Orders,
    title: 'Orders',
  },
  {
    path: '/plannedorders/:orderid',
    pathForTitle: '/plannedorders/',
    component: PlannedOrderDetail,
    title: 'Gepland Order Detail',
  },
  {
    path: '/plannedorders',
    component: MyOrders,
    title: 'Geplande Orders',
  },
  {
    path: '/executedorders',
    component: ExecutedOrders,
    title: 'Uitgevoerde Orders',
  },
  {
    path: '/staff',
    component: Staff,
    title: 'Personeel',
  },
  {
    path: '/addstaff',
    component: AddStaff,
    title: 'Personeel toevoegen',
  },
  {
    path: '/vehicles',
    component: Vehicles,
    title: 'Voertuigen',
  },
  {
    path: '/addvehicle',
    component: AddVehicle,
    title: 'Voertuig toevoegen',
  },
  {
    path: '/reports',
    component: Reports,
    title: 'Rapporten',
  },
  {
    path: '/reports/plannedorders',
    component: PlannedOrders,
    title: 'Rapport Geplande Ritten',
  },
  {
    path: '/reports/executedorders',
    component: ExecutedOrdersReport,
    title: 'Rapport Uitgevoerde Ritten',
  },
  {
    path: '/reports/pricedorders',
    component: PricedOrders,
    title: 'PDF Totalen 75%',
  },
];

const Router = (props) => {
  const createRoute = (path, Component, index) => {
    return <Route key={index} path={path} element={<Component />} />;
  };

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {routes.map((item, i) => {
          if (typeof item.path === 'string') {
            return createRoute(item.path, item.component, i);
          }
          return item.path.map((subItem, i2) =>
            createRoute(subItem, item.component, i + '_' + i2)
          );
        })}
        <Route
          path="*"
          element={
            <HashedRoute>
              <Orders />
            </HashedRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Router;

export const getRouteTitle = (path) => {
  let title;
  for (var i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (
      path.indexOf(route.pathForTitle ? route.pathForTitle : route.path) === 0
    ) {
      title = route.title;
      break;
    }
  }
  return title || DEFAULTPATHNAME;
};
