import React, { Suspense, useContext } from 'react';
import Loader from './components/Loader/Loader';
import SwitchLocaleModal from './components/SwitchLocaleModal/SwitchLocaleModal';
import { Context } from './components/IntlWrapper/IntlWrapper';
import intlConfig from './i18n/intl-config';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale, setLocale } from './features/app/appSlice';
import Main from './Main';

const App = () => {
  const currentLocale = useSelector(selectLocale);
  const context = useContext(Context);
  const dispatch = useDispatch();

  const changeLocale = (locale) => {
    context.selectLanguage(locale);
    localStorage.setItem('locale', locale);
    dispatch(setLocale(locale));
  };

  const activeLocales = intlConfig.activeLanguages.map((activeLang) => {
    return { i18n: activeLang, value: intlConfig.languages[activeLang].lang };
  });

  if (
    (!currentLocale && intlConfig.activeLanguages.length === 1) ||
    (currentLocale && currentLocale !== context.locale)
  ) {
    changeLocale(currentLocale || intlConfig.defaultLanguage);
  }

  return (
    <div>
      {!currentLocale && (
        <SwitchLocaleModal locales={activeLocales} change={changeLocale} />
      )}
      {context.locale && (
        <Suspense fallback={<Loader forceFetching={true} />}>
          <Main />
        </Suspense>
      )}
    </div>
  );
};

export default App;
