import English from './en.json';
import Dutch from './nl.json';

const config = {
  forceLanguageSwitcher: true,
  defaultLanguage: 'nl',
  activeLanguages: ['nl'],
  languages: {
    nl: {
      lang: 'Nederlands',
      translations: Dutch,
    },
    en: {
      lang: 'English',
      translations: English,
    },
  },
};

export default config;
