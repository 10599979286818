import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import Activate from './views/account/Activate';
import AppMain from './views/app/AppMain';
import RequireAuth from './views/auth/RequireAuth';

const Signin = lazy(() => import('./views/account/Signin'));
const Signup = lazy(() => import('./views/account/Signup'));

const Main = () => {
  return (
    <Suspense fallback={<Loader forceFetching={true} />}>
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/activate" element={<Activate />} />
        <Route
          path="*"
          element={
            <RequireAuth to="/signin">
              <AppMain />
            </RequireAuth>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Main;
