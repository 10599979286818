import CustomTable, {
  TableConfig,
  TableField,
} from '../../../components/Table/CustomTable';
import { appURI, defaultPaging } from '../../../config';
import { getToken } from '../../../localstorage/auth';

const ExecutedOrders = () => {
  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/orders/employer/executed`,
        headers: endPointHeaders,
      },
    },
    null,
    { ...defaultPaging, rowsPerPage: 20 },
    {
      order: 'desc',
      orderBy: 'execDateUTC',
    },
    [
      new TableField('ordernr', 'ordernr', 'Ordernr'),
      new TableField('execDateUTC', 'execDateUTC', 'Datum', 0, 0, 'EE dd/MM'),
      new TableField('driver', 'driver.alias', 'Chauffeur'),
      new TableField('vehiclecategory', 'vehicleCategory.name', 'Voertuig'),
      new TableField(
        'pickuptime',
        'route.firstPickupTime',
        'Ophalen',
        0,
        0,
        'HH:mm'
      ),
      new TableField(
        'route',
        'route.traject.cityLocations',
        'Route',
        0,
        0,
        0,
        0,
        true
      ),
      new TableField('employerExecutedPrice', 'employerExecutedPrice', 'Prijs'),
    ]
  );

  return (
    <div>
      <CustomTable handleEvent={-1} config={tableConfig} />
    </div>
  );
};

export default ExecutedOrders;
