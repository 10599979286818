import { appID } from '../config';

export function setToken(jwt) {
  localStorage.setItem(`${appID}-token`, jwt);
}

export function getToken() {
  const token = localStorage.getItem(`${appID}-token`);
  return token;
}

export function clearToken() {
  localStorage.removeItem(`${appID}-token`);
}

export function clear() {
  clearToken();
}
