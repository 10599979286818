import { getTokenUser } from '../../../../auth/auth';
import Page from '../../../../components/Page/Page';
import CustomTable, {
  TableConfig,
  TableField,
} from '../../../../components/Table/CustomTable';
import { appURI } from '../../../../config';
import { getToken } from '../../../../localstorage/auth';

const PricedOrders = () => {
  const user = getTokenUser();
  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/reports/employerpdfsv2/`,
        headers: endPointHeaders,
        method: 'GET',
      },
    },
    null,
    null,
    {
      order: 'asc',
      orderBy: 'route.firstPickupTime',
    },
    [
      new TableField(
        'created',
        'pdf.created',
        'Aanmaakdatum',
        0,
        0,
        'dd/MM/yyyy EEEE'
      ),
      new TableField('period', 'period', 'Periode'),
      new TableField('amount', 'amount', 'Bedrag', 0, 0, 0, 0, 0, 0, 0, true),
      new TableField('driver', 'driver.alias', 'Chauffeur'),
      new TableField('pdffile', 'pdf.Location', 'Bestand', 0, 0, 0, 0, true),
    ],
    false
  );

  return (
    <Page alignLeft>
      <CustomTable handleEvent={-1} config={tableConfig} noSelection />
    </Page>
  );
};

export default PricedOrders;
