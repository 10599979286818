export const appID = 'gorseleone';
export const appTitle = 'Gorselé One';
export const geodataApiURI = 'https://gorsele-geodata.herokuapp.com/api/v2/';
export const googleGeocodeApiURI =
  'https://maps.googleapis.com/maps/api/geocode/json';
export const appURI =
  process.env.NODE_ENV === 'development'
    ? 'https://gorseleone-back.herokuapp.com/api/v1/'
    : 'https://gorseleone-back.herokuapp.com/api/v1/';
export const oneofficeURI =
  'https://gorsele-oneoffice-back.herokuapp.com/api/anonymous';
export const priceWaitingPerHour = 30;
export const pricePercentage = true;
export const percentageForEmployerPrice = 0.7;
export const defaultPaging = {
  page: 0,
  rowsPerPage: 100,
  rowsPerPageOptions: [10, 20, 50, 100],
  count: 0,
};
export const awsAccessKey = 'AKIAIWRD7OKAMHABPE7Q';
export const awsSecretKey = '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';
export const bucketName = 'gorsele-uploads';
export const GOOGLE_API_KEY = 'AIzaSyDxxAPjtL_IoxLephQ4LvWORwe-LFHpF64';
