import { configureStore } from '@reduxjs/toolkit';
import app from '../features/app/appSlice';
import address from '../features/address/addressSlice';
import { geodataApi } from '../services/geodata/geodataApi';
import { garageApi } from '../services/garage/garageApi';

export default configureStore({
  reducer: {
    app,
    address,
    [geodataApi.reducerPath]: geodataApi.reducer,
    [garageApi.reducerPath]: garageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(geodataApi.middleware, garageApi.middleware),
});
