import { useState } from 'react';
import { onSignOut } from '../../auth/auth';
import styles from './AppMain.module.scss';
import { styled } from '@mui/material/styles';
import Navigation from './navigation/Navigation';
import Router from './Router';
import HeaderAppBar from './header/HeaderAppBar';
import { useNavigate } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

const DRAWERWiDTH = 240;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

const AppMain = () => {
  const [navOpen, setNavOpen] = useState(isBrowser ? true : false);
  const navigate = useNavigate();

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const signOut = () => {
    onSignOut();
    navigate('/signin');
  };

  return (
    <div className={`app ${styles.root}`}>
      <HeaderAppBar
        toggleNavigation={toggleNav}
        signout={signOut}
        open={navOpen}
        drawerWidth={DRAWERWiDTH}
      />
      <Navigation
        open={navOpen}
        closeNavigation={() => setNavOpen(false)}
        drawerWidth={DRAWERWiDTH}
      />
      <section
        id="main"
        className={styles.main}
        style={{
          marginLeft: navOpen ? DRAWERWiDTH : 0,
          width: navOpen ? `calc(100% - ${DRAWERWiDTH}px)` : '100%',
        }}
      >
        <DrawerHeader />
        <Router />
      </section>
    </div>
  );
};

export default AppMain;
