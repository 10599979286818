import { Link } from 'react-router-dom';
import { getTokenUser } from '../../../auth/auth';
import Page from '../../../components/Page/Page';

const Reports = () => {
  const user = getTokenUser();
  return (
    <Page alignLeft>
      {user.canCheckEmployerTotals ? (
        <ul>
          <li>
            <Link to="/reports/plannedorders">Rapport geplande ritten</Link>
          </li>
          <li>
            <Link to="/reports/executedorders">Rapport uitgevoerde ritten</Link>
          </li>
          <li>
            <Link to="/reports/pricedorders">PDF's totalen 75% </Link>
          </li>
        </ul>
      ) : (
        <p>Geen toegang tot rapporten</p>
      )}
    </Page>
  );
};

export default Reports;
