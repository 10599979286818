import { Snackbar, Alert } from '@mui/material';
import React, { useState } from 'react';

export const SnackContext = React.createContext();

const _delay = 5000;
const _position = {
  vertical: 'top',
  horizontal: 'right',
};

class SnackVariant {
  static Error = new SnackVariant('error');
  static Warning = new SnackVariant('warning');
  static Info = new SnackVariant('info');
  static Success = new SnackVariant('success');

  constructor(variant) {
    this.variant = variant;
  }

  toString = () => {
    return this.variant;
  };
}

const SnackWrapper = (props) => {
  const [message, setMessage] = useState(undefined);
  const [delay, setDelay] = useState(_delay);
  const [position, setPosition] = useState(_position);
  const [variant, setVariant] = useState(undefined);

  const snackClose = () => {
    setMessage(undefined);
  };

  const showSnack = (message, variant, delay, position) => {
    setMessage(message);
    setDelay(delay || _delay);
    setPosition(position || _position);
    setVariant(variant || SnackVariant.Error.toString());
  };

  return (
    <SnackContext.Provider value={{ showSnack }}>
      <Snackbar
        anchorOrigin={
          position || {
            vertical: 'top',
            horizontal: 'center',
          }
        }
        open={Boolean(message)}
        autoHideDuration={delay}
        onClose={snackClose}
      >
        <Alert severity={variant || SnackVariant.Error.toString()}>
          {message}
        </Alert>
      </Snackbar>
      {props.children}
    </SnackContext.Provider>
  );
};

export default SnackWrapper;
