import { FormattedMessage } from 'react-intl';
import styles from './Layout.module.scss';

const Layout = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.head} />
      <div className={styles.main}>
        <p className={styles.title}>
          <FormattedMessage id="app.title" />
        </p>
        <p className={styles.subtitle}>
          <FormattedMessage id="app.subtitle" />
        </p>
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
