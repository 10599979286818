import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRouteTitle } from '../Router';

const HeaderAppBar = ({ toggleNavigation, signout, open, drawerWidth }) => {
  const auth = true;
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const signOut = (e) => {
    e.preventDefault();
    handleClose();
    signout();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      style={{
        marginLeft: open ? drawerWidth : 0,
        width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
        transition: 'all 0.3s',
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{ mr: 2 }}
          style={{ color: 'white', display: open ? 'none' : 'inherit' }}
          onClick={() => toggleNavigation()}
        >
          <MenuIcon />
        </IconButton>
        <p
          style={{
            width: '100%',
            color: 'white',
            letterSpacing: 1,
          }}
        >
          {getRouteTitle(location.pathname)}
        </p>
        {auth && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              style={{ color: 'white' }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              className="app-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={signOut} style={{ textDecoration: 'none' }}>
                Afmelden
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderAppBar;
