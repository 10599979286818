import {
  CheckBoxOutlined,
  Filter1Rounded,
  FilterListRounded,
  FilterRounded,
  Print,
  SortRounded,
} from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Checkbox,
  TablePagination,
  Tooltip,
  alpha,
  Button,
  Select,
  MenuItem,
  ButtonBase,
  Menu,
} from '@mui/material';
import { format } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { percentageForEmployerPrice } from '../../config';
import { fixedDecimals } from '../../utils/number';
import { getNestedPropsFromString } from '../../utils/object';

const DEFAULTENDPOINTMETHOD = 'POST';
const DEFAULTENDPOINTHEADERS = { 'Content-Type': 'application/json' };

export const FORCERELOADEVENT = new Event('FORCERELOAD');

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export class LabelColor {
  constructor(val, operator, color) {
    this.val = val;
    this.operator = operator;
    this.color = color;
  }
}

export class TableField {
  constructor(
    id,
    property,
    label,
    isNumeric,
    isBoolean,
    dateFormat,
    disablePadding,
    maxWidth,
    isPrice75,
    labelColor,
    isPrice
  ) {
    this.id = id;
    this.property = property;
    this.label = label;
    this.isNumeric = isNumeric;
    this.isBoolean = isBoolean;
    this.dateFormat = dateFormat;
    this.disablePadding = disablePadding;
    this.maxWidth = maxWidth;
    this.isPrice75 = isPrice75;
    this.labelColor = labelColor;
    this.isPrice = isPrice;
  }
}

export class TableFilter {
  constructor(label, type, placeholder, filterProp) {
    this.label = label;
    this.type = type;
    this.placeholder = placeholder;
    this.filterProp = filterProp;
  }
}

export class TableConfig {
  constructor(
    endpoints,
    actions,
    paginate,
    order,
    fields,
    multiSelect,
    disabledProperties
  ) {
    this.endpoints = endpoints;
    this.actions = actions;
    this.paginate = paginate;
    this.order = order;
    this.fields = fields;
    this.multiSelect = multiSelect;
    this.disabledProperties = disabledProperties;
  }
}

export class TableActionDialog {
  constructor(title, text, type, confirmLabel, declineLabel) {
    this.title = title;
    this.text = text;
    this.type = type;
    this.confirmLabel = confirmLabel;
    this.declineLabel = declineLabel;
  }
}

export class TableAction {
  constructor(
    id,
    icon,
    label,
    buttonProps,
    noHandler,
    endpoint,
    confirmationDialog,
    successDialog,
    failedDialog,
    refreshOnSuccess
  ) {
    this.id = id;
    this.icon = icon;
    this.label = label;
    this.buttonProps = buttonProps || {};
    this.noHandler = noHandler;
    this.endpoint = endpoint;
    this.confirmationDialog = confirmationDialog;
    this.successDialog = successDialog;
    this.failedDialog = failedDialog;
    this.refreshOnSuccess = refreshOnSuccess || false;
  }
}

const CustomTable = ({
  config,
  onAction,
  enablePrint,
  noSelection,
  sorting,
  handleEvent,
  showFilter,
  onFilter,
  sendResultRows,
  onRowsResult,
}) => {
  const [currentEvent, setCurrentEvent] = useState();
  const [rows, setRows] = useState([]);
  const [activeEndpoint, setActiveEndpoint] = useState('default');
  const [fetching, setFetching] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [paginate, setPaginate] = useState(config ? config.paginate : null);
  const [order, setOrder] = useState(
    (config.order && config.order.order) || 'asc'
  );
  const [orderBy, setOrderBy] = useState(
    (config.order && config.order.orderBy) || '_id'
  );
  const [activeSort, setActiveSort] = useState(undefined);

  const EnhancedTableToolbar = ({
    numSelected,
    total,
    multiSelect,
    from,
    to,
    actions,
    sorting,
    changeSort,
    activeSort,
    performAction,
    enablePrint,
    onPrint,
    ...rest
  }) => {
    const [sortAnchorEl, setSortAnchorEl] = useState(null);
    const fromTo =
      from !== undefined && to !== undefined ? `| ${from} tot ${to} ` : '';
    const selected = multiSelect ? `| ${numSelected} geselecteerd` : '';

    const handleSortMenu = (e) => {
      setSortAnchorEl(e.currentTarget);
    };

    const handleSortClose = () => {
      setSortAnchorEl(null);
    };

    return (
      <Toolbar
        {...rest}
        style={{
          padding: isBrowser ? '10px 16px' : isTablet ? '8px 4px' : '2px 2px',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: isBrowser ? 56 : isTablet ? 36 : 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: 1,
          }}
        >
          {showFilter && onFilter && (
            <Button
              style={{ textAlign: 'right' }}
              startIcon={<FilterListRounded />}
              onClick={onFilter}
              color="secondary"
            >
              Filter
            </Button>
          )}
          {(sorting && sorting.length && (
            <div>
              <Button
                style={{ textAlign: 'right' }}
                startIcon={<SortRounded />}
                onClick={handleSortMenu}
              >
                Sorteer
              </Button>
              <Menu
                id="menu-sort"
                anchorEl={sortAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(sortAnchorEl)}
                onClose={handleSortClose}
              >
                {sorting.map((sortItem, s) => (
                  <MenuItem
                    key={s}
                    onClick={() => changeSort(sortItem.sort)}
                    style={{ textDecoration: 'none' }}
                  >
                    {sortItem.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )) ||
            null}
          {total && false ? (
            <Typography color="inherit" variant="subtitle1" component="div">
              {`Totaal : ${total} ${fromTo}${selected}`}
            </Typography>
          ) : null}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {numSelected > 0 && actions
            ? actions.map((action, a) => (
                <div style={{ marginLeft: 10, marginBottom: 6 }}>
                  <Tooltip title={action.label} key={a}>
                    <Button
                      size="small"
                      startIcon={action.icon}
                      onClick={() => performAction(action)}
                      aria-label={action.id}
                      {...action.buttonProps}
                    >
                      {action.label}
                    </Button>
                  </Tooltip>
                </div>
              ))
            : null}
          {enablePrint && (
            <Tooltip title="Print">
              <Button
                size="small"
                startIcon={<Print />}
                onClick={() => onPrint()}
                aria-label="print"
              >
                Print
              </Button>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    );
  };

  const EnhancedTableHead = ({
    fields,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    multiSelect,
    actions,
    ...rest
  }) => {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead {...rest}>
        <TableRow style={{ padding: 0 }}>
          {isBrowser && !noSelection && (
            <TableCell padding="checkbox" size="small">
              {actions && (
                <Checkbox
                  disabled={!multiSelect}
                  color="primary"
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  style={{ visibility: !multiSelect ? 'hidden' : 'visible' }}
                />
              )}
            </TableCell>
          )}
          {fields.map((field, f) => (
            <EnhancedTableCell
              key={f}
              field={field}
              sortDirection={orderBy === field.id ? order : false}
              size="small"
            >
              <TableSortLabel
                active={orderBy === field.id}
                direction={orderBy === field.id ? order : 'asc'}
                onClick={createSortHandler(field.id)}
                disabled={true}
              >
                {field.label}
              </TableSortLabel>
            </EnhancedTableCell>
          ))}
          {actions && actions.length ? (
            <TableCell padding="checkbox" size="small"></TableCell>
          ) : null}
        </TableRow>
      </TableHead>
    );
  };

  const EnhancedTableCell = ({
    children,
    field,
    labelColor,
    tooltip,
    ...rest
  }) => {
    return (
      <TableCell
        id={field.id}
        align={field.isNumeric ? 'right' : 'left'}
        padding={field.disablePadding ? 'none' : 'normal'}
        style={{
          width: field.maxWidth ? '100%' : 'auto',
          padding: isBrowser ? '12px 6px' : isTablet ? '12px 4px' : '6px 2px',
          margin: 0,
          fontSize: isBrowser ? 12 : isTablet ? 10 : 8,
          lineHeight: 1,
        }}
        {...rest}
      >
        <Tooltip title={tooltip || ''}>
          <span
            className={`tablelabel ${labelColor ? 'labelwithcolor' : ''}`}
            style={{
              backgroundColor: children
                ? labelColor || 'transparant'
                : 'transparent',
            }}
          >
            {children}
          </span>
        </Tooltip>
      </TableCell>
    );
  };

  const EnhancedTableBodyCell = ({ item, field, ...rest }) => {
    const fieldVal = getNestedPropsFromString(field.property, item, true);
    const isLink =
      fieldVal && fieldVal.length && typeof fieldVal === 'string'
        ? fieldVal.indexOf('http://') === 0 ||
          fieldVal.indexOf('https://') === 0
          ? true
          : false
        : false;
    let labelColor;
    let tooltip;
    if (field.labelColor && field.labelColor.length) {
      let val = fieldVal;
      if (field.dateFormat) {
        val = new Date(fieldVal).getTime();
      }
      for (let lc = 0; lc < field.labelColor.length; lc++) {
        const lcItem = field.labelColor[lc];
        if (lcItem.tooltip) {
          tooltip = tooltip ? tooltip + ', ' : '';
          tooltip += lcItem.tooltip;
        }
        switch (lcItem.operator) {
          case '<':
            labelColor = val < lcItem.val ? lcItem.color : null;
            break;
          case '>':
            labelColor = val > lcItem.val ? lcItem.color : null;
            break;
          case '=':
            labelColor = val === lcItem.val ? lcItem.color : null;
            break;
          default:
            labelColor = lcItem.color || null;
            break;
        }
      }
    }
    return (
      <EnhancedTableCell
        field={field}
        {...rest}
        tooltip={tooltip}
        labelColor={labelColor}
      >
        {field.dateFormat ? (
          fieldVal ? (
            format(new Date(fieldVal), field.dateFormat, { locale: nlBE })
          ) : null
        ) : (field.isPrice || field.isPrice75) && parseFloat(fieldVal) ? (
          field.isPrice ? (
            `€${fixedDecimals(parseFloat(fieldVal))}`
          ) : (
            `€${fixedDecimals(
              parseFloat(fieldVal) * percentageForEmployerPrice
            )}`
          )
        ) : field.isBoolean ? (
          Boolean(fieldVal) ? (
            <CheckBoxOutlined />
          ) : null
        ) : isLink ? (
          <a
            href={fieldVal}
            target="_blank"
            rel="noreferrer"
            style={{ fontSize: 12, letterSpacing: 0 }}
          >
            Open link
          </a>
        ) : (
          fieldVal
        )}
      </EnhancedTableCell>
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n._id);
      const newSelectedItems = rows.map((n) => n);
      setSelected(newSelecteds);
      setSelectedItems(newSelectedItems);
      return;
    }
    setSelected([]);
    setSelectedItems([]);
  };

  const handleSortChange = (sort) => {
    setActiveSort(sort);
    callEndpoint(null, null, sort);
  };

  const handleClick = (event, item) => {
    const id = item._id;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newSelectedItems = [];

    if (!config.multiSelect) {
      if (selectedIndex === -1) {
        newSelected.push(id);
        newSelectedItems.push(item);
      }
    } else {
      if (selectedIndex !== -1) {
        for (let n = 0; n < selectedItems.length; n++) {
          if (selectedItems[n]._id !== id) {
            newSelectedItems.push(selectedItems[n]);
          }
        }
      }
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
        newSelectedItems = newSelectedItems.concat(selectedItems, item);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    }

    setSelected(newSelected);
    setSelectedItems(newSelectedItems);
    window.scrollTo(0, 0);
    if (onAction) {
      onAction({ id: 'select' }, newSelected, newSelectedItems);
    }
  };

  const handleChangePage = (event, newPage) => {
    const newPaginate = { ...paginate, page: newPage };
    setPaginate(newPaginate);
    callEndpoint(null, newPaginate);
  };

  const handleChangeRowsPerPage = (event) => {
    const newPaginate = {
      ...paginate,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    };
    setPaginate(newPaginate);
    callEndpoint(null, newPaginate);
  };

  const isSelected = (_id) => selected.indexOf(_id) !== -1;

  const callEndpoint = async (forceEndpoint, forcePaginate, forceSort) => {
    setSelected([]);
    setCurrentEvent(handleEvent);
    const useEndpoint = config.endpoints
      ? forceEndpoint || config.endpoints[activeEndpoint]
      : null;

    if (useEndpoint && (!useEndpoint.callOnBodyNotNull || useEndpoint.body)) {
      setFetching(true);
      const usePaginate = forcePaginate || paginate;
      const useMethod = useEndpoint.method || DEFAULTENDPOINTMETHOD;
      if (!useEndpoint) return;
      const url = useEndpoint.uri;
      const endPointBody = useEndpoint.body || {};
      const options = {
        method: useMethod,
        headers: useEndpoint.headers || DEFAULTENDPOINTHEADERS,
        body:
          useMethod === 'POST'
            ? JSON.stringify({
                paginate: usePaginate,
                sorting: forceSort
                  ? forceSort
                  : activeSort
                  ? activeSort
                  : sorting
                  ? sorting[0].sort
                  : null,
                ...endPointBody,
              })
            : null,
      };
      const res = await fetch(url, options);
      setFetching(false);
      const data = await res.json();
      if (!data || !data.success || !data.result) {
        if (data && data.error) {
          return Swal.fire('Probleem', data.error, 'error');
        }
        return Swal.fire(
          'Probleem',
          'Er is een probleem opgetreden tijdens het ophalen van de data',
          'error'
        );
      }
      if (sendResultRows && onRowsResult) {
        onRowsResult(data);
      }
      setRows(data.result);
      if (data.total !== undefined && paginate) {
        setPaginate({ ...usePaginate, count: data.total });
      }
    }
  };

  const handleAction = (action, item) => {
    if (onAction && action.noHandler) {
      if (selected.length && selectedItems.length) {
        return onAction(action, selected, selectedItems);
      } else if (item) {
        return onAction(action, [item._id], [item]);
      }
      return onAction(action);
    }
    if (action.endpoint) {
      return callEndpoint(action.endpoint);
    }
  };

  const isCheckDisabled = (row, disabledProps) => {
    let disabled = false;
    if (disabledProps && disabledProps.length) {
      for (let dp = 0; dp < disabledProps.length; dp++) {
        const value = getNestedPropsFromString(
          disabledProps[dp].prop,
          row,
          true
        );
        if (value === disabledProps[dp].value) {
          disabled = true;
          break;
        }
      }
    }
    return disabled;
  };

  const forceDataReload = () => {
    callEndpoint();
  };

  const printCurrentRows = () => {
    var mywindow = window.open('', 'PRINT');
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );
    mywindow.document.write(
      '</head><body ><table width="100%" border="1" cellspacing="0">'
    );
    let tableContent = '';
    if (config.fields && config.fields.length && rows && rows.length) {
      tableContent = '<tr>';
      for (let f = 0; f < config.fields.length; f++) {
        const field = config.fields[f];
        tableContent += `<td>${field.label}</td>`;
      }
      tableContent += '</tr>';
      const items = stableSort(rows, getComparator(order, orderBy));
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        tableContent += '<tr>';
        for (let f = 0; f < config.fields.length; f++) {
          const field = config.fields[f];
          const fieldVal = getNestedPropsFromString(field.property, item, true);
          tableContent += `<td>${
            field.dateFormat
              ? fieldVal
                ? format(new Date(fieldVal), field.dateFormat, { locale: nlBE })
                : null
              : (field.isPrice || field.isPrice75) && parseFloat(fieldVal)
              ? field.isPrice
                ? `€${fixedDecimals(parseFloat(fieldVal))}`
                : `€${fixedDecimals(parseFloat(fieldVal) * 0.75)}`
              : field.isBoolean
              ? Boolean(fieldVal)
                ? 'JA'
                : 'NEE'
              : fieldVal
          }</td>`;
        }
        tableContent += '</tr>';
      }
    }
    mywindow.document.write(tableContent);
    mywindow.document.write('</table></body></html>');
    mywindow.document.close();
    mywindow.focus();
    return true;
  };

  useEffect(() => {
    if (config.endpoints && handleEvent !== currentEvent) {
      callEndpoint();
    }
  }, [config, handleEvent]);

  return (
    <div style={{ width: '100%' }}>
      <EnhancedTableToolbar
        multiSelect={config.multiSelect}
        numSelected={selected.length}
        total={paginate ? paginate.count : rows.length}
        from={
          paginate
            ? paginate.page
              ? paginate.page * paginate.rowsPerPage
              : 0
            : undefined
        }
        to={
          paginate
            ? (parseInt(paginate.page) + 1) * paginate.rowsPerPage
            : undefined
        }
        sorting={sorting}
        changeSort={handleSortChange}
        activeSort={activeSort}
        actions={config.actions}
        performAction={handleAction}
        enablePrint={enablePrint && rows.length ? true : false}
        onPrint={printCurrentRows}
      />
      {fetching && (
        <ScaleLoader
          size={45}
          color={'#000000'}
          loading={true}
          style={{ marginTop: 20 }}
        />
      )}
      {rows && rows.length ? (
        <TableContainer>
          <Table stickyHeader>
            <EnhancedTableHead
              actions={config.actions}
              numSelected={selected.length}
              fields={config.fields}
              rowCount={paginate ? paginate.rowsPerPage : rows.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              multiSelect={config.multiSelect}
            />
            <TableBody id="customtable">
              {stableSort(rows, getComparator(order, orderBy)).map((row, r) => {
                const isItemSelected = isSelected(row._id);
                return (
                  <TableRow
                    key={r}
                    hover
                    onClick={(event) =>
                      noSelection
                        ? null
                        : config.disabledProperties &&
                          isCheckDisabled(row, config.disabledProperties)
                        ? null
                        : handleClick(event, row)
                    }
                  >
                    {isBrowser && !noSelection && (
                      <TableCell padding="checkbox">
                        {config.actions && (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            disabled={
                              config.disabledProperties
                                ? isCheckDisabled(
                                    row,
                                    config.disabledProperties
                                  )
                                : false
                            }
                          />
                        )}
                      </TableCell>
                    )}
                    {config.fields
                      ? config.fields.map((field, f) => (
                          <EnhancedTableBodyCell
                            item={row}
                            field={field}
                            key={f}
                          />
                        ))
                      : null}
                    {config.actions && (
                      <TableCell
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: isBrowser
                            ? '12px 6px'
                            : isTablet
                            ? '12px 4px'
                            : '6px 2px',
                          margin: 0,
                        }}
                      >
                        {config.actions.map((action, a) => {
                          const extraProps = action.buttonProps || {};
                          const { style, ...rest } = extraProps;
                          return action.icon ? (
                            <Tooltip key={a} title={action.label || ''}>
                              <span
                                className="spanbtn"
                                style={{ marginLeft: 10, marginBottom: 6 }}
                                onClick={() => handleAction(action, row)}
                              >
                                {action.icon}
                              </span>
                            </Tooltip>
                          ) : (
                            <Button
                              size="small"
                              style={{
                                fontSize: isBrowser ? 12 : isTablet ? 10 : 8,
                                minWidth: 48,
                                marginLeft: 10,
                                marginBottom: 6,
                                ...style,
                              }}
                              key={a}
                              onClick={() => handleAction(action, row)}
                              {...rest}
                            >
                              {action.label}
                            </Button>
                          );
                        })}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {paginate && (
            <TablePagination
              component="div"
              count={paginate.count}
              rowsPerPage={paginate.rowsPerPage}
              page={paginate.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={'Rijen per pagina'}
              rowsPerPageOptions={paginate.rowsPerPageOptions}
            />
          )}
        </TableContainer>
      ) : (
        <p style={{ marginLeft: 20 }}>Geen resultaten gevonden</p>
      )}
    </div>
  );
};

export default CustomTable;
