import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { geodataApiURI } from '../../config';

export const geodataApi = createApi({
  reducerPath: 'geodataApi',
  baseQuery: fetchBaseQuery({ baseUrl: geodataApiURI }),
  endpoints: (builder) => ({
    listCountries: builder.query({
      query: () => 'countries/',
    }),
    listCitiesByCountry: builder.query({
      query: ({ country, search }) =>
        `cities/countryid/${country}/search/${search}`,
    }),
  }),
});

export const { useListCountriesQuery, useListCitiesByCountryQuery } =
  geodataApi;
