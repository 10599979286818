/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@mui/material';
import { format } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import Form from '../../../../components/Form/Form';
import Page from '../../../../components/Page/Page';
import { appURI, oneofficeURI } from '../../../../config';
import { getToken } from '../../../../localstorage/auth';
import styles from './OrderDetail.module.scss';
import { getTokenUser } from '../../../../auth/auth';

const OrderDetail = () => {
  const navigate = useNavigate();
  const { orderid } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState();
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [plannedOrders, setPlannedOrders] = useState([]);
  const [plannedOrdersForDriver, setPlannedOrdersForDriver] = useState([]);
  const [leaveConflict, setLeaveConflict] = useState();
  const [leave, setLeave] = useState([]);
  const [conflicted, setConflicted] = useState(false);
  const user = getTokenUser();

  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const planOrder = (e) => {
    e.preventDefault();

    const alertThis = `${
      conflicted
        ? !leaveConflict
          ? 'LET OP! Deze chauffeur is in conflict met een andere order!'
          : 'LET OP! Deze chauffeur heeft verlof tijdens deze rit!'
        : ''
    }`;

    Swal.fire({
      title: 'Inplannen',
      text: `Ben je zeker dat je rit wil inplannen${
        !user.isPartner
          ? ` bij ${selectedDriver.alias} met ${selectedVehicle.licensePlate} ${selectedVehicle.model.fullModel}`
          : ''
      }?${alertThis?.length ? ` ${alertThis}` : ''}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Ja plan in',
      cancelButtonText: 'Annuleer',
    }).then(async (planResult) => {
      if (planResult && planResult.isConfirmed) {
        const orderRes = await fetch(`${oneofficeURI}/gorseleone/byids`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderids: [orderDetail._id],
          }),
        });
        const orderData = await orderRes.json();
        if (
          !orderData ||
          !orderData.success ||
          !orderData.result ||
          !orderData.result.length
        ) {
          Swal.fire(
            'Geen orders',
            'Het systeem kon geen orders vinden om in te plannen. Mogelijks werd het order reeds ingepland door Gorselé zelf. Gelieve een ander order te selecteren.',
            'error'
          );
          return navigate('/orders');
        }
        let staffMember = selectedDriver;
        let vehicle = selectedVehicle;
        const theOrder = orderData.result[0];
        if (theOrder.planned || theOrder.isBeingPlanned) {
          Swal.fire(
            'Reeds ingepland',
            'Het is niet mogelijk dit order in te plannen omdat het ondertussen reeds werd ingepland.',
            'error'
          );
          return navigate('/orders');
        }
        const planBody = user.isPartner
          ? {
              orderid: orderDetail._id,
              partner: user.partner,
            }
          : {
              orderid: orderDetail._id,
              staff: staffMember,
              vehicle,
            };
        const planOneOffice = await fetch(
          `${oneofficeURI}/gorseleone/planv2${user.isPartner ? 'partner' : ''}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(planBody),
          }
        );
        const oneOfficeData = await planOneOffice.json();
        if (!oneOfficeData || !oneOfficeData.success) {
          Swal.fire(
            'Probleem',
            'Het order kon niet meer ingepland worden. Mogelijks werd het order reeds door een dispatcher van Gorselé ingepland.',
            'error'
          );
          return navigate('/orders');
        }
        const planGorseleOne = await fetch(
          `${appURI}protected/orders/plan${user.isPartner ? 'partner' : ''}`,
          {
            method: 'POST',
            headers: endPointHeaders,
            body: JSON.stringify(planBody),
          }
        );
        const gorseleOneData = await planGorseleOne.json();
        if (!gorseleOneData || !gorseleOneData.success) {
          Swal.fire(
            'Probleem',
            'Er is een probleem opgetreden tijdens het inplannen. Indien het probleem blijft aanhouden contacteer Gorselé.',
            'error'
          );
          return navigate('/orders');
        }
        Swal.fire(
          'Ingepland',
          'Het inplannen is afgerond en je ontvangt zo dadelijk een bevestigingsmail. Eén van de dispatchers zal uw aanvraag beoordelen en het inplannen bevestigen of afwijzen. Dit kan even duren.',
          'success'
        );
        setOrderDetail(undefined);
        setDrivers(undefined);
        setVehicles(undefined);
        setSelectedDriver(undefined);
        setSelectedVehicle(undefined);
        setPlannedOrders([]);
        setPlannedOrdersForDriver([]);
        setLeaveConflict(undefined);
        setLeave([]);
        return navigate('/orders');
      }
    });
  };

  const loadEmployerPlanning = async (execDateUTC) => {
    setLoading(true);
    const url = `${appURI}protected/orders/plannedbydate/${execDateUTC}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    });
    setLoading(false);
    const data = await res.json();
    if (data?.success && data?.result) {
      setPlannedOrders(data.result);
    }
  };

  const loadEmployerVehicles = async (vehicleCategory) => {
    setLoading(true);
    const url = `${appURI}protected/vehicles/${vehicleCategory.seats}/${
      vehicleCategory.onlyPeople
        ? 'people'
        : vehicleCategory.onlyMaterial
        ? 'material'
        : 'both'
    }`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    });
    setLoading(false);
    const data = await res.json();
    if (data?.success && data?.result) {
      setVehicles(data.result);
    }
  };

  const loadOrderDetail = async () => {
    setLoading(true);
    const url = `${appURI}protected/orders/detail/${orderid}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    });
    setLoading(false);
    const data = await res.json();
    if (!data || !data.success || !data.orderDetail) {
      Swal.fire('Probleem', 'Probleem bij ophalen order details', 'error');
      return navigate('/orders');
    }
    if (data.orderDetail.route.data?.duration) {
      const orderEndTime =
        data.orderDetail.route.firstPickupTime +
        data.orderDetail.route.data.duration * 60 * 1000 +
        15 * 60 * 1000;
      data.orderDetail.estimatedEndTime = orderEndTime;
    }
    setOrderDetail(data.orderDetail);
    setSelectedDriver(undefined);
    setSelectedVehicle(undefined);
    const drivers = location?.state?.drivers ? location.state.drivers : null;
    if (!user.isPartner) {
      if (drivers && drivers.length) {
        setDrivers(drivers);
        loadEmployerPlanning(data.orderDetail.execDateUTC);
        loadEmployerVehicles(data.orderDetail.vehicleCategory);
      } else {
        Swal.fire(
          'Probleem',
          'Geen beschikbare chauffeurs om dit order in te plannen.',
          'error'
        );
        return navigate('/orders');
      }
    }
  };

  const onVehicleSelect = (vehicleid) => {
    let theVehicle;
    for (let d = 0; d < vehicles.length; d++) {
      if (vehicles[d]._id === vehicleid) {
        theVehicle = vehicles[d];
        break;
      }
    }
    setSelectedVehicle(theVehicle);
  };

  const onDriverSelect = async (driverid) => {
    let theDriver;
    for (let d = 0; d < drivers.length; d++) {
      if (drivers[d]._id === driverid) {
        theDriver = drivers[d];
        break;
      }
    }

    let leave = [];
    setLoading(true);
    const url = `${appURI}protected/leave/date/${orderDetail.execDateUTC}/staff/${theDriver._id}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    });

    setLoading(false);
    const data = await res.json();
    if (data?.success && data?.leave?.length) {
      leave = data.leave;
      setLeave(data.leave);
    } else {
      leave = [];
      setLeave(undefined);
    }

    let lConflict = undefined;
    if (leave?.length) {
      const estimatedEndTime =
        orderDetail.route.firstPickupTime +
        parseInt(orderDetail.route.data?.duration || 15, 10) * 60 * 1000 +
        15 * 60 * 1000;
      leave.forEach((leaveItem) => {
        if (leaveItem.realHours?.length) {
          leaveItem.realHours.forEach((leaveHour) => {
            if (
              leaveHour.fromUTC <= orderDetail.route.firstPickupTime &&
              leaveHour.toUTC >= orderDetail.route.firstPickupTime
            ) {
              lConflict = leaveItem;
            }
            if (
              leaveHour.fromUTC <= estimatedEndTime &&
              leaveHour.toUTC >= estimatedEndTime
            ) {
              lConflict = leaveItem;
            }
            if (
              leaveHour.fromUTC >= orderDetail.route.firstPickupTime &&
              leaveHour.toUTC <= estimatedEndTime
            ) {
              lConflict = leaveItem;
            }
          });
        }
      });
    }
    setLeaveConflict(lConflict);
    setPlannedOrdersForDriver([]);
    setSelectedDriver(theDriver);
    if (theDriver) {
      let inConflict = false;
      if (plannedOrders.length) {
        let plannedForDriver = [];
        for (let p = 0; p < plannedOrders.length; p++) {
          const pOrder = plannedOrders[p];
          let add = false;
          if (pOrder.waitingGorseleOneApproval) {
            if (pOrder.gorseleOneDriver?._id === theDriver._id) {
              add = true;
            }
          } else {
            if (pOrder.driver?._id === theDriver._id) {
              add = true;
            }
          }
          if (add) {
            if (
              pOrder.route.data?.duration &&
              orderDetail.route.data?.duration
            ) {
              const pOrderStartTime =
                pOrder.route.firstPickupTime - 15 * 60 * 1000;
              const pOrderEndTime =
                pOrder.route.firstPickupTime +
                parseInt(pOrder.route.data?.duration || 15, 10) * 60 * 1000 +
                15 * 60 * 1000;
              pOrder.estimatedEndTime = pOrderEndTime;
              const orderStartTime =
                orderDetail.route.firstPickupTime - 15 * 60 * 1000;
              const orderEndTime =
                orderDetail.route.firstPickupTime +
                parseInt(orderDetail.route.data?.duration || 15, 10) *
                  60 *
                  1000 +
                15 * 60 * 1000;

              if (
                pOrderStartTime <= orderStartTime &&
                pOrderEndTime >= orderStartTime
              ) {
                inConflict = true;
              }
              if (
                pOrderStartTime <= orderEndTime &&
                pOrderEndTime >= orderEndTime
              ) {
                inConflict = true;
              }
              if (
                pOrderStartTime < orderStartTime &&
                pOrderEndTime > orderEndTime
              ) {
                inConflict = true;
              }
              if (
                pOrderStartTime >= orderStartTime &&
                pOrderEndTime <= orderEndTime
              ) {
                inConflict = true;
              }
              if (inConflict) {
                pOrder.conflicted = true;
              }
            }
            plannedForDriver.push(pOrder);
          }
        }
        setPlannedOrdersForDriver(plannedForDriver);
      }
      setConflicted(inConflict ? true : lConflict ? true : false);
    }
  };

  useEffect(() => {
    if (orderid) {
      loadOrderDetail();
    }
  }, [orderid]);

  return (
    <Page>
      {loading && <ScaleLoader loading />}
      {!loading && orderDetail ? (
        <>
          <div className={styles.data}>
            <p>
              <strong>Order:</strong> {orderDetail.ordernr}
            </p>
            <p>
              <strong>Datum:</strong>{' '}
              {format(orderDetail.execDateUTC, 'EEEE dd/MM/yyyy', {
                locale: nlBE,
              })}
            </p>
            <p>
              <strong>Voertuig:</strong> {orderDetail.vehicleCategory.name}
            </p>
            <p>
              <strong>Route</strong>
              <br />
              {orderDetail.route.routeString.full}
            </p>
            {orderDetail.estimatedEndTime ? (
              <p>
                <strong>Geschatte eindtijd:</strong>{' '}
                {format(orderDetail.estimatedEndTime, 'HH:mm dd/MM', {
                  locale: nlBE,
                })}
              </p>
            ) : null}
            {orderDetail.remark ||
            orderDetail.remarkCustom ||
            orderDetail.hasTickets ? (
              <p>
                <strong>Opmerkingen</strong>
                <br />
                {orderDetail.hasTickets
                  ? 'Materiaal of tickets meenemen! '
                  : null}
                {orderDetail.remark ? orderDetail.remark.text : null}
                {orderDetail.remarkCustom ? orderDetail.remarkCustom : null}
              </p>
            ) : null}
            {orderDetail.calculatedEmployerPrice &&
            parseFloat(orderDetail.calculatedEmployerPrice) ? (
              <p>
                <strong>Prijs</strong>
                <br />
                {orderDetail.calculatedEmployerPrice}
              </p>
            ) : null}
          </div>
          {!user.isPartner ? (
            <Form
              onSubmit={planOrder}
              fullwidth
              style={{ backgroundColor: '#e3e3e3', marginTop: 12, padding: 8 }}
            >
              <select
                value={selectedDriver?._id || ''}
                onChange={(e) => onDriverSelect(e.currentTarget.value)}
              >
                <option value="">Selecteer een chauffeur</option>
                {drivers.map((driver, d) => (
                  <option key={d} value={driver._id}>
                    {driver.alias}
                  </option>
                ))}
              </select>
              <p className={styles.hint}>
                Gorselé selecteert zelf chauffeurs die deze rit mogen uitvoeren
              </p>
              {selectedDriver ? (
                <select
                  style={{ marginTop: 6, marginBottom: 20 }}
                  value={selectedVehicle?._id || ''}
                  onChange={(e) => onVehicleSelect(e.currentTarget.value)}
                >
                  <option value="">Selecteer een voertuig</option>
                  {vehicles.map((vehicle, v) => (
                    <option key={v} value={vehicle._id}>
                      {vehicle.licensePlate} {vehicle.model.fullModel}
                    </option>
                  ))}
                </select>
              ) : null}
              {selectedDriver && selectedVehicle && (
                <Button type="submit" variant="contained" size="small">
                  Inplannen {conflicted ? 'met conflict' : ''}{' '}
                  {leaveConflict ? 'terwijl chauffeur verlof heeft' : ''}
                </Button>
              )}
            </Form>
          ) : (
            <Form
              onSubmit={planOrder}
              fullwidth
              style={{ backgroundColor: '#e3e3e3', marginTop: 12, padding: 8 }}
            >
              <Button type="submit" variant="contained" size="small">
                Inplannen
              </Button>
            </Form>
          )}

          {leaveConflict && selectedDriver ? (
            <Form
              fullwidth
              style={{
                backgroundColor: '#faa7a7',
                marginTop: 12,
                padding: 8,
                marginBottom: 8,
              }}
            >
              <div className={styles.data}>
                <p>
                  Rit in conflict met gepland verlof van {selectedDriver.alias}.
                  <br />
                  <strong>Type verlof:</strong> {leaveConflict.type}
                </p>
                <p>
                  <strong>Uren:</strong>
                </p>
                <ul>
                  {leaveConflict.hours.map((leaveH, l) => (
                    <li key={l}>{leaveH.fullRange}</li>
                  ))}
                </ul>
              </div>
            </Form>
          ) : null}

          {plannedOrdersForDriver?.length && selectedDriver ? (
            <Form
              fullwidth
              style={{ backgroundColor: '#c5f0d0', marginTop: 12, padding: 8 }}
            >
              <div className={styles.data}>
                <p>
                  Reeds ingepland bij {selectedDriver.alias} (
                  {`${plannedOrdersForDriver.length} rit${
                    plannedOrdersForDriver.length > 1 ? 'ten' : ''
                  }`}
                  )
                </p>
                {plannedOrdersForDriver.map((pOrder, p) => (
                  <div
                    key={p}
                    style={{
                      width: '100%',
                      backgroundColor: pOrder.conflicted
                        ? '#faa7a7'
                        : '#e6faeb',
                      padding: 4,
                      marginBottom: 6,
                      borderRadius: 8,
                    }}
                  >
                    {pOrder.conflicted ? (
                      <p>
                        <strong>MOGELIJKS IN CONFLICT</strong>
                      </p>
                    ) : null}
                    <p>
                      <strong>Order:</strong> {pOrder.ordernr}
                    </p>
                    <p>
                      <strong>Voertuig:</strong> {pOrder.vehicleCategory.name}
                    </p>
                    <p>
                      <strong>Route</strong>
                      <br />
                      {pOrder.route.routeString.short}
                    </p>
                    {pOrder.estimatedEndTime ? (
                      <p>
                        <strong>Geschatte eindtijd:</strong>{' '}
                        {format(pOrder.estimatedEndTime, 'HH:mm dd/MM', {
                          locale: nlBE,
                        })}
                      </p>
                    ) : null}
                  </div>
                ))}
              </div>
            </Form>
          ) : null}

          {leave?.length && selectedDriver ? (
            <Form
              fullwidth
              style={{
                backgroundColor: 'lightgrey',
                marginTop: 12,
                padding: 8,
                marginBottom: 8,
              }}
            >
              <div className={styles.data}>
                <p>
                  Gepland verlof van {selectedDriver.alias} op{' '}
                  {format(orderDetail.execDateUTC, 'dd/MM/yyyy')}.
                </p>
                {leave.map((leaveItem, l) => (
                  <div
                    key={l}
                    style={{
                      marginTop: 4,
                      paddingTop: 4,
                      borderTop: '1px solid grey',
                      width: '100%',
                    }}
                  >
                    <p>
                      <strong>Type verlof:</strong> {leaveItem.type}
                    </p>
                    <p>
                      <strong>Uren:</strong>
                    </p>
                    <ul>
                      {leaveItem.hours.map((leaveH, l) => (
                        <li key={l}>{leaveH.fullRange}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </Form>
          ) : null}
        </>
      ) : null}
    </Page>
  );
};

export default OrderDetail;
