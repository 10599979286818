const config = {
  appVersion: '2.0.0',
  defaultPaginate: {
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    count: 0,
  },
};

export default config;
