import { useSelector } from 'react-redux';
import { selectFetching } from '../../features/app/appSlice';
import styles from './Loader.module.scss';

const Loader = ({ forceFetching }) => {
  const fetching = useSelector(selectFetching);

  if (forceFetching || fetching) {
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <div className={styles.spinner} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Loader;
