import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import Swal from 'sweetalert2';
import Form from '../../../../components/Form/Form';
import Page from '../../../../components/Page/Page';
import StyledSelect from '../../../../components/StyledSelect/StyledSelect';
import CustomTable, {
  TableConfig,
  TableField,
} from '../../../../components/Table/CustomTable';
import { appURI } from '../../../../config';
import { getToken } from '../../../../localstorage/auth';

const ExecutedOrdersReport = () => {
  const [eventID, setEventID] = useState(-1);
  const [searchItem, setSearchItem] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [searchBody, setSearchBody] = useState(null);

  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const updateItem = (prop, val) => {
    setSearchItem({ ...searchItem, [prop]: val });
  };

  const loadDrivers = async () => {
    const res = await fetch(`${appURI}protected/staff`, {
      headers: endPointHeaders,
    });
    const data = await res.json();
    if (!data || !data.success || !data.result || !data.result.length) {
      return Swal.fire(
        'Geen personeel',
        'Het systeem kon geen personeelsleden vinden.',
        'error'
      );
    }
    setDrivers(data.result);
  };

  const search = (e) => {
    e.preventDefault();
    setEventID(Date.now());
    setSearchBody({
      search: { ...searchItem },
    });
  };

  useState(() => {
    loadDrivers();
  }, []);

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/reports/executedorders`,
        headers: endPointHeaders,
        body: searchBody,
        callOnBodyNotNull: true,
      },
    },
    null,
    null,
    {
      order: 'asc',
      orderBy: 'route.firstPickupTime',
    },
    [
      new TableField('ordernr', 'ordernr', 'Ordernr'),
      new TableField(
        'execDateUTC',
        'execDateUTC',
        'Uitvoerdatum',
        0,
        0,
        'dd/MM/yyyy EEEE'
      ),
      new TableField('vehiclecategory', 'vehicleCategory.name', 'Voertuig'),
      new TableField(
        'pickuptime',
        'route.firstPickupTime',
        'Ophaaltijd',
        0,
        0,
        'HH:mm'
      ),
      new TableField('driver', 'driver.alias', 'Chauffeur'),
      new TableField(
        'route',
        'route.routeString.short',
        'Route',
        0,
        0,
        0,
        0,
        true
      ),
    ],
    false
  );

  return (
    <Page alignLeft>
      <div>
        <Form onSubmit={search} fullwidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            required
            value={searchItem.dateFrom || ''}
            onChange={(e) => updateItem('dateFrom', e.target.value)}
            label="Datum van"
            placeholder=""
            margin="dense"
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            required
            value={searchItem.dateTo || ''}
            onChange={(e) => updateItem('dateTo', e.target.value)}
            label="Datum tot"
            placeholder=""
            margin="dense"
          />
          <StyledSelect
            options={drivers || []}
            isSearchable={true}
            value={searchItem.driver || null}
            placeholder="Chauffeur"
            noOptionsMessage={() => 'Geen chauffeurs'}
            getOptionLabel={(option) => option['alias']}
            onChange={(value) => updateItem('driver', value)}
          />
          <Button type="submit">Zoeken</Button>
        </Form>
        <CustomTable
          config={tableConfig}
          enablePrint={true}
          handleEvent={eventID}
        />
      </div>
    </Page>
  );
};

export default ExecutedOrdersReport;
