import styles from './SwitchLocaleModal.module.scss';

const SwitchLocaleModal = ({ locales, change }) => {
  return (
    <div className={styles.root}>
      <main aria-label="Kies Taal" className={styles.picker}>
        {' '}
        {locales.map((loc, i) => {
          return (
            <a
              aria-label={loc.value}
              href={loc.i18n}
              className={styles.option}
              key={i}
              onClick={(e) => {
                e.preventDefault();
                change(loc.i18n);
              }}
            >
              {loc.value}
            </a>
          );
        })}{' '}
      </main>
    </div>
  );
};

export default SwitchLocaleModal;
