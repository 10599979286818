import styles from './Page.module.scss';

const Page = ({ children, alignLeft, ...rest }) => {
  return (
    <div
      className={`${styles.root} ${alignLeft ? styles.alignleft : null}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Page;
