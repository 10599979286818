import {
  Assignment,
  ChevronLeft,
  Description,
  Group,
  LocalTaxi,
  Report,
} from '@mui/icons-material';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { DrawerHeader } from '../AppMain';
import styles from './Navigation.module.scss';
import { getTokenUser } from '../../../auth/auth';

export const navigationItems = [
  {
    id: 'orders',
    icon: <Description />,
    text: 'Orders',
    path: '/orders',
  },
  {
    id: 'plannedorders',
    icon: <Assignment />,
    text: 'Geplande orders',
    path: '/plannedorders',
  },
  {
    id: 'executedorders',
    icon: <Assignment />,
    text: 'Uitgevoerde orders',
    path: '/executedorders',
  },
  {
    id: 'staff',
    icon: <Group />,
    text: 'Personeel',
    path: '/staff',
    disablePartner: true,
  },
  {
    id: 'vehicles',
    icon: <LocalTaxi />,
    text: 'Voertuigen',
    path: '/vehicles',
    disablePartner: true,
  },
  {
    id: 'reports',
    icon: <Report />,
    text: 'Rapporten',
    path: '/reports',
    disablePartner: true,
  },
];

const Navigation = ({ open, closeNavigation, drawerWidth }) => {
  const user = getTokenUser();

  return (
    <Drawer
      open={open}
      onClose={() => closeNavigation()}
      className={styles.navigation}
      variant="persistent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <DrawerHeader>
        <span className={styles.title}>Gorselé One</span>
        <IconButton onClick={closeNavigation}>
          <ChevronLeft />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List component="nav" className={styles.list}>
        {navigationItems.map((item, i) => {
          return user.isPartner && item.disablePartner ? null : (
            <div key={i}>
              <NavLink
                to={item.path}
                onClick={() => {
                  return true;
                }}
                className={({ isActive }) =>
                  isActive ? styles.activeLink : ''
                }
              >
                <ListItem button>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.text}</ListItemText>
                </ListItem>
              </NavLink>
            </div>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Navigation;
