exports.createGeometryFromGeocodeResult = (result) => {
  if (result && result.geometry) {
    const { location } = result.geometry;
    return {
      type: 'Point',
      coordinates: [location.lng, location.lat],
    };
  }
  return null;
};
