import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './styles/style.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import store from './app/store';
import IntlWrapper from './components/IntlWrapper/IntlWrapper';
import { BrowserRouter } from 'react-router-dom';
import SnackWrapper from './components/SnackWrapper/SnackWrapper';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlWrapper>
        <SnackWrapper>
          <BrowserRouter>
            <CssBaseline />
            <App />
          </BrowserRouter>
        </SnackWrapper>
      </IntlWrapper>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
