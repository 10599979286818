import Layout from './Layout';
import { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import { Link, useSearchParams } from 'react-router-dom';
import { appURI } from '../../config';
import { Button } from '@mui/material';

const Activate = () => {
  const [activated, setActivated] = useState(true);
  const [activateError, setActivateError] = useState();
  let [searchParams, setSearchParams] = useSearchParams();

  const activateUID = async (uid) => {
    const url = `${appURI}anonymous/signup/activate`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid,
      }),
    });
    const data = await res.json();
    if (!data || !data.success) {
      setActivateError(true);
    }
    setActivated(true);
  };

  useEffect(() => {
    if (searchParams) {
      const uid = searchParams.get('uid');
      if (!uid) {
        setActivateError(true);
      }
      activateUID(uid);
    }
  }, [searchParams]);

  return (
    <Layout>
      <div style={{ color: 'white', textAlign: 'center' }}>
        {!activated && !activateError ? (
          <ScaleLoader color="#ffffff" loading={true} />
        ) : null}
        {activated ? (
          <div>
            <p>Uw account werd geactiveerd!</p>
            <div style={{ marginTop: 30 }}>
              <Link to="/signin">
                <Button variant="contained" color="secondary">
                  Aanmelden
                </Button>
              </Link>
            </div>
          </div>
        ) : null}
        {activateError ? (
          <p>
            Uw account kon niet geactiveerd worden. Contacteer Gorselé bij
            problemen.
          </p>
        ) : null}
      </div>
    </Layout>
  );
};

export default Activate;
