import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: [],
  cities: [],
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
  },
});

export const { setCountries, setCities } = addressSlice.actions;

export const selectCountries = (state) => state.address.countries;
export const selectCities = (state) => state.address.cities;

export default addressSlice.reducer;
