export const round = (number, decimals) => {
  if (decimals && decimals > 0) {
    var decimalMultiplier = Math.pow(10, decimals) + 0.0;
    return (
      Math.round(parseFloat(number * (decimalMultiplier * 10)) / 10) /
      decimalMultiplier
    );
  } else {
    return Math.round(number);
  }
};

export const fixedDecimals = (number, decimals = 2) => {
  return parseFloat(round(number, decimals)).toFixed(decimals);
};

export const stringToNumber = (n) => {
  n = String(n) || '';
  n =
    n.indexOf('.') > n.indexOf(',')
      ? n.replace(/,/g, '')
      : n.replace(/\./g, '');
  n = n.replace(/,/g, '.');
  return parseFloat(n);
};

export const fixedDecimalsWithLocaleNotation = (
  number,
  decimals = 2,
  locale = 'en-US'
) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).format(parseFloat(round(number, decimals)));
};
