import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { getTokenUser } from '../../../auth/auth';
import FileUpload from '../../../components/Form/FileUpload';
import Form from '../../../components/Form/Form';
import Page from '../../../components/Page/Page';
import StyledSelect from '../../../components/StyledSelect/StyledSelect';
import {
  awsAccessKey,
  awsSecretKey,
  bucketName,
  oneofficeURI,
} from '../../../config';
import { getToken } from '../../../localstorage/auth';
import { useListVehicleModelsQuery } from '../../../services/garage/garageApi';

const AddVehicle = () => {
  const [item, setItem] = useState({});
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();
  const { data: vehicleModels, isLoading: vehicleModelsLoading } =
    useListVehicleModelsQuery();

  const updateItem = (prop, val) => {
    setItem({
      ...item,
      [prop]: val,
    });
  };

  const uploadComplete = (documents) => {
    Swal.fire('Gelukt', 'Upload afgerond', 'success');
    const currentDocs = item.documents || [];
    const newDocs = currentDocs.concat(documents);
    setItem({
      ...item,
      documents: newDocs,
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    const user = getTokenUser();
    setSending(true);
    const url = `${oneofficeURI}/gorseleone/addvehiclev2`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        vehicle: {
          ...item,
        },
      }),
    });
    setSending(false);
    const data = await res.json();
    if (!data || !data.success) {
      if (data && data.error) {
        return Swal.fire('Probleem', data.error, 'error');
      }
      return Swal.fire(
        'Probleem',
        'Er is een probleem opgetreden tijdens het aanmaken van het voertuig. Probeer het later opnieuw of contacteer Gorselé.',
        'error'
      );
    }
    Swal.fire(
      'Gelukt',
      'Het nieuwe voertuig werd aangemaakt en wordt nu door een dispatcher van Gorselé gecontroleerd. Dit proces kan tot 24u duren. Indien alle gegevens in orde zijn zal het voertuig beschikbaar zijn in Gorselé One.',
      'success'
    );
    return navigate('/vehicles');
  };

  return (
    <Page>
      <Form onSubmit={submit} fullwidth>
        <h1>Nieuw voertuig</h1>
        <StyledSelect
          required
          options={vehicleModels || []}
          isSearchable={true}
          value={item.model || null}
          placeholder="Voertuig model"
          noOptionsMessage={() => 'Geen modellen'}
          getOptionLabel={(option) => option['fullModel']}
          isLoading={vehicleModelsLoading}
          onChange={(value) => updateItem('model', value)}
        />
        <p className="hint">
          Indien u het model niet kan terugvinden contacteer dan Gorselé en
          vraag om uw voertuig model toe te voegen.
        </p>
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.builtDate || ''}
          onChange={(e) => updateItem('builtDate', e.target.value)}
          label="Bouwdatum"
          placeholder=""
          margin="dense"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.registeredDate || ''}
          onChange={(e) => updateItem('registeredDate', e.target.value)}
          label="Inschrijvingsdatum"
          placeholder=""
          margin="dense"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.insuranceEndDate || ''}
          onChange={(e) => updateItem('insuranceEndDate', e.target.value)}
          label="Verzekering geldig tot"
          placeholder=""
          margin="dense"
        />
        <TextField
          required
          value={item.permitNr || ''}
          onChange={(e) => updateItem('permitNr', e.target.value)}
          label="Vergunningsnummer"
          margin="dense"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          type="date"
          required
          value={item.permitEndDate || ''}
          onChange={(e) => updateItem('permitEndDate', e.target.value)}
          label="Vergunning geldig tot"
          placeholder=""
          margin="dense"
        />
        <TextField
          required
          value={item.licensePlate || ''}
          onChange={(e) => updateItem('licensePlate', e.target.value)}
          label="Nummerplaat"
          margin="dense"
        />
        <TextField
          required
          value={item.chassisNr || ''}
          onChange={(e) => updateItem('chassisNr', e.target.value)}
          label="Chassis nummer"
          margin="dense"
        />
        <TextField
          type="number"
          required
          value={item.seats || ''}
          onChange={(e) => updateItem('seats', e.target.value)}
          label="Aantal zitplaatsen"
          margin="dense"
        />
        <TextField
          type="number"
          value={item.currentKM || ''}
          onChange={(e) => updateItem('currentKM', e.target.value)}
          label="Huidig aantal KM op teller"
          margin="dense"
        />
        {item.documents && (
          <fieldset>
            <legend>Documenten</legend>
            <table width="100%">
              <tbody>
                {item.documents.map((doc, d) => (
                  <tr key={d}>
                    <td>{doc.originalName}</td>
                    <td>
                      <a href={doc.Location} target="_blank" rel="noreferrer">
                        Open document
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </fieldset>
        )}
        <fieldset>
          <legend>Voeg documenten toe</legend>
          <p className="hint">
            Gelieve voor- en achterkant van inschrijvingsbewijs,
            verzekeringsdocument en vergunning toe te voegen.
          </p>
          <FileUpload
            onUploadComplete={(files) => uploadComplete(files)}
            onError={() => Swal.fire('Probleem met upload', 'error')}
            bucketName={bucketName}
            awsAccessKey={awsAccessKey}
            awsSecretKey={awsSecretKey}
            multiple={false}
            accept="image/png, image/jpeg"
            prefix="vehicle"
          />
        </fieldset>
        <div>
          <Button
            variant="contained"
            style={{ marginTop: 10 }}
            size="large"
            type="submit"
          >
            Opslaan
          </Button>
        </div>
        {sending && (
          <ScaleLoader
            size={45}
            color={'#000000'}
            loading={true}
            style={{ marginTop: 20 }}
          />
        )}
      </Form>
    </Page>
  );
};

export default AddVehicle;
