import { Navigate, useLocation } from 'react-router-dom';
import { getTokenUser } from '../../auth/auth';

const RequireAuth = ({ to, children, ...rest }) => {
  let user = getTokenUser();
  let location = useLocation();
  if (!user) {
    return <Navigate to={to} state={{ from: location }} replace {...rest} />;
  } else {
    return children;
  }
};

export default RequireAuth;
