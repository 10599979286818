import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import config from '../../i18n/intl-config';

export const Context = React.createContext();

const storedLocale = localStorage.getItem('locale');
const devicelocale = String(storedLocale || navigator.language).split('-')[0];
let selectedLocale = storedLocale || devicelocale;

// Create flat strings from nested json for react-intl v2
export const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

// Return translations (messages) by locale or use default locale
const getTranslationsByLocale = (locale) => {
  let mainLocale = locale ? locale.split('-')[0] : null;
  if (!locale) {
    return {};
  }
  if (config.activeLanguages.indexOf(mainLocale) > -1) {
    selectedLocale = mainLocale;
    return config.languages[mainLocale].translations;
  }
  selectedLocale = config.defaultLanguage;
  return config.languages[config.defaultLanguage].translations;
};

const lang = getTranslationsByLocale(storedLocale || devicelocale);

const IntlWrapper = (props) => {
  const [locale, setLocale] = useState(selectedLocale);
  const [messages, setMessages] = useState(lang);

  const selectLanguage = (newLocale) => {
    setLocale(newLocale);
    setMessages(getTranslationsByLocale(newLocale));
  };

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={flattenMessages(messages)} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default IntlWrapper;
