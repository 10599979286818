import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getTokenUser } from '../../../auth/auth';
import CustomTable, {
  LabelColor,
  TableConfig,
  TableField,
} from '../../../components/Table/CustomTable';
import { appURI } from '../../../config';
import { getToken } from '../../../localstorage/auth';

const Staff = () => {
  const navigate = useNavigate();
  const user = getTokenUser();
  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/staff`,
        method: 'GET',
        headers: endPointHeaders,
      },
    },
    [],
    null,
    {
      order: 'asc',
      orderBy: 'alias',
    },
    [
      new TableField('alias', 'alias', 'Naam'),
      new TableField('gsm', 'gsm', 'Gsm'),
      new TableField('gsm', 'gsmWork', 'Werkgsm'),
      new TableField('email', 'email', 'E-mail'),
      new TableField('city', 'city.fullCity', 'Woonplaats'),
      new TableField('material', 'allowMaterial', 'Materiaal', 0, true),
      new TableField('license', 'driversLicense', 'Rijbewijs'),
      new TableField(
        'driverpasenddate',
        'driverPassEndDate',
        'Bestuurderspas tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
      new TableField(
        'driverpassnumber',
        'driverPassNumber',
        'Bestuurderspasnummer'
      ),
      new TableField(
        'medicalexaminationdate',
        'medicalExaminationDate',
        'Schifting tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
      new TableField(
        'verbruggeenddate',
        'verbruggeEndDate',
        'Verbrugge tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
      new TableField(
        'kloosterboerenddate',
        'kloosterboerEndDate',
        'Kloosterboer tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
    ]
  );

  const addStaff = () => {
    navigate('/addstaff');
  };

  return (
    <div>
      <div style={{ width: '100%', textAlign: 'right', padding: '10px 16px' }}>
        {user.canCheckEmployerTotals ? (
          <Button variant="contained" size="small" onClick={() => addStaff()}>
            <Add /> Nieuwe toevoegen
          </Button>
        ) : null}
      </div>
      <CustomTable handleEvent={-1} config={tableConfig} noSelection />
    </div>
  );
};

export default Staff;
