import Select from 'react-select';
import { supportsPassiveEvents } from 'detect-it';

const selectStyles = {
  option: (styles) => ({
    ...styles,
    whiteSpace: 'pre-wrap',
  }),
  control: (styles) => ({
    ...styles,
    margin: 0,
    minHeight: 52,
    paddingLeft: 12,
    boxShadow: 'none',
    marginTop: 16,
    marginBottom: 8,
    backgroundColor: 'white',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
    marginTop: 0,
    zIndex: 9999,
    backgroundColor: 'white',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
    left: -1,
    whiteSpace: 'pre-wrap',
  }),
};

const StyledSelect = (props) => {
  return (
    <Select
      styles={selectStyles}
      {...props}
      captureMenuScroll={supportsPassiveEvents ? false : true}
      style={{ marginBottom: 12 }}
    />
  );
};

export default StyledSelect;
