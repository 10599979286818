import decode from 'jwt-decode';
import { setToken, getToken, clearToken } from '../localstorage/auth';

export const onSignIn = (jwt) => {
  setToken(jwt);
};

export const getTokenUser = () => {
  let token = getJWTToken();
  if (!token || isTokenExpired(token)) {
    return null;
  }
  let decoded = token && decode(token);
  if (decoded && decoded.user) {
    let u = decoded.user;
    return u;
  }
  return null;
};

export const onSignOut = () => {
  clearToken();
};

export const getJWTToken = () => {
  return getToken();
};

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return new Date(0);
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}

export const isSignedIn = () => {
  let token = getJWTToken();
  return !!token && !isTokenExpired(token);
};

export const requireAuth = (nextState, replace) => {
  if (!isSignedIn()) {
    replace({ pathname: '/signin' });
  }
};
