export const getNestedPropsFromString = (string, obj, flat) => {
  var value = undefined;
  var level = 0;
  if (
    string &&
    string.length &&
    obj &&
    Object.getOwnPropertyNames(obj).length
  ) {
    string.split('.').forEach((dataEl, i) => {
      if (i === level) {
        if (i === 0) {
          if (obj[dataEl]) {
            value = obj[dataEl];
            level++;
          }
        } else {
          if (value && value[dataEl]) {
            value = value[dataEl];
            level++;
          }
        }
      }
    });
  }
  if (Array.isArray(value)) {
    value = value.join(', ');
  }
  if (
    flat &&
    typeof value !== 'string' &&
    typeof value !== 'number' &&
    typeof value !== 'boolean'
  ) {
    value = null;
  }
  return value;
};

export const sortArrayOfObjects = (array, sort) => {
  if (
    array &&
    array.length &&
    sort &&
    Object.getOwnPropertyNames(sort).length
  ) {
    var sortProps = Object.getOwnPropertyNames(sort);
    let sortFunc = (a, b) => {
      var res;
      sortProps.forEach((s) => {
        var v1 = (a && getNestedPropsFromString(s, a)) || 0;
        var v2 = (b && getNestedPropsFromString(s, b)) || 0;
        if (typeof v1 === 'string') {
          v1 = v1.toLowerCase();
          v2 = v2.toLowerCase();
        }
        if (sort[s] === 1) {
          res = res || v1 < v2 ? -1 : v1 > v2 ? 1 : res;
        } else {
          res = res || v1 < v2 ? 1 : v1 > v2 ? -1 : res;
        }
      });
      return Number(res);
    };
    array.sort(sortFunc);
  }
  return array;
};

export const convertArrayToObject = (array, key, prop) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: prop ? getNestedPropsFromString(prop, item) : item,
    };
  }, initialValue);
};
