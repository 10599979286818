import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const isHashed = (path) => {
  return path.indexOf('#/') > -1;
};

const unHash = (path) => {
  if (path) {
    let newPath = path.replace('#/app/', '');
    newPath = newPath.replace('#/', '');
    return newPath;
  }
  return;
};

const HashedRoute = ({ children }) => {
  let location = useLocation();
  if (location && location.hash && isHashed(location.hash)) {
    return <Navigate to={unHash(location.hash)} state={{ from: location }} />;
  }
  return children;
};

export default HashedRoute;
