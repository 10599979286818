import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getTokenUser } from '../../../auth/auth';
import CustomTable, {
  LabelColor,
  TableConfig,
  TableField,
} from '../../../components/Table/CustomTable';
import { appURI } from '../../../config';
import { getToken } from '../../../localstorage/auth';

const Vehicles = () => {
  const navigate = useNavigate();
  const user = getTokenUser();
  const endPointHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
  };

  const tableConfig = new TableConfig(
    {
      default: {
        uri: `${appURI}protected/vehicles`,
        method: 'GET',
        headers: endPointHeaders,
      },
    },
    [],
    null,
    {
      order: 'asc',
      orderBy: 'licensePlate',
    },
    [
      new TableField('licenseplate', 'licensePlate', 'Nummerplaat'),
      new TableField('model', 'model.fullModel', 'Model'),
      new TableField('chassisnr', 'chassisNr', 'ChassisNr'),
      new TableField(
        'insuranceenddate',
        'insuranceEndDate',
        'Verzekering tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
      new TableField(
        'permitenddate',
        'permitEndDate',
        'Vergunning tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
      new TableField(
        'inspectionenddate',
        'inspectionEndDate',
        'Keuring tot',
        0,
        0,
        'dd/MM/yyyy',
        0,
        undefined,
        0,
        [
          new LabelColor(Date.now() - 30 * 24 * 60 * 60 * 1000, '<', '#fcdea7'),
          new LabelColor(Date.now(), '<', '#ffabab'),
        ]
      ),
      new TableField('plexi', 'plexiGlass', 'Plexischerm', 0, true),
      new TableField('towbar', 'hasTowbar', 'Trekhaak', 0, true),
    ]
  );

  const addVehicle = () => {
    navigate('/addvehicle');
  };

  return (
    <div>
      <div style={{ width: '100%', textAlign: 'right', padding: '10px 16px' }}>
        {user.canCheckEmployerTotals ? (
          <Button variant="contained" onClick={() => addVehicle()}>
            <Add /> Nieuw voertuig toevoegen
          </Button>
        ) : null}
      </div>
      <CustomTable handleEvent={-1} config={tableConfig} />
    </div>
  );
};

export default Vehicles;
